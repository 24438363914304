// VARIABLES
var slider;
var imgwidth;
var currentPosition = 0;
var owl = $('.owl-carousel');
var resizeTimer;
var pad=15;

// FUNCTIONS

function setCaption(){
  var currentCaption = $('#imgcol .active img').data('caption');
  $(".caption").html(currentCaption);
}

// jQuery erweitern, vertikale Positionierung zu sichern
// http://stackoverflow.com/questions/210717/using-jquery-to-center-a-div-on-the-screen
jQuery.fn.centerVertically = function () {
  var freeSpace = ($(window).height() - $(this).outerHeight());
  this.css("margin-top", Math.max(0, (freeSpace / 2) + $(window).scrollTop()) + "px");
  return this;
}


function launchSlider(){
  // owl starten
  owl.owlCarousel({
    autoWidth:false,
    loop:false,
    nav:false,
    dots:false,
    lazyLoad:true,
    smartSpeed:150,
    fluidSpeed:150,
    rewind: false,
    center: true,
    items:1,
    URLhashListener:true,
    autoplayHoverPause:true,
    startPosition: 'URLHash',
    onInitialized: setCaption,
    onTranslated: setCaption
  });

  // thumbs aktivieren
  $(".imgbox").click(function(){
    // get number of thumb
    var n = $(this).index();
    // go to that image in slider
    owl.trigger('to.owl.carousel', n);
  });
}

// Mobile Nav schön vor Bild legen
function setMobileNavPos(){
  // offset = top + left
  var imgoffset = $('#imgcol').offset();
  console.log(imgoffset);
  var offsetTop = imgoffset.top + pad;
  var offsetLeft = imgoffset.left + pad;
  var menuWidth = $('#imgcol').width()-(pad*2);
  //var menuHeight = $('#imgcol').height()-(pad*2);
  $("#mobileNav").width(menuWidth).offset({ top: offsetTop, left: offsetLeft });
}

function toggleMobileNav(){
  if (($("#mobileNav")).is(':visible')) {
    // reset positioning and hide
    $("#mobileNav, #mobileNavBG").css({
      'top': 'auto',
      'left': 'auto'
    }).hide();
  }
  else {
    // show
    setMobileNavPos();
    $("#mobileNav, #mobileNavBG").show();
  }
}

// EVENTS

// ON ARROW KEYS LEFT / RIGHT

// add Keyboard Nav to Owl
$(document).on('keydown', function( event ) { //attach event listener
  if(event.keyCode == 37) {
    owl.trigger('prev.owl')
  }
  if(event.keyCode == 39) {
    owl.trigger('next.owl')
  }
});


// ON RESIZE / ORIENTATIONCHANGE
// https://css-tricks.com/snippets/jquery/done-resizing-event/
$(window).on('orientationchange resize', function(e) {

  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(function() {

    // Run code here, resizing has "stopped"

    // resize mobile nav (not sure if ever needed)
    if (($("#mobileNav")).is(':visible')) {
      setMobileNavPos();
    }
    // center vertically
    $('.wrapper').centerVertically();
    // vielleicht erweitern
    //$(".hideonload").css("visibility","visible");

    // debug
    console.log(Foundation.MediaQuery.current);

  }, 250);

});

// ON DOMREADY
$(document).ready(function() {
  setTimeout(
    function(){
      launchSlider(0);
      $(".hideonload").css("visibility","visible");
      // positionierungen, die sonst per CSS (flex) den slider stören
      $('.wrapper').centerVertically();
    }, 250);

    // mobile Nav
    $(".mobileNavTrigger").click(function(){
      toggleMobileNav();
    });
    //  enable nav close
    $("#mobileNav .close-button").click(function(){
      toggleMobileNav();
    });

    // alle externen Links und PDFs in neuem Fenster öffnen
    $("a[href^='http']:not([href*='" + window.location.host + "'])").attr("target", "_blank");
    $("a[href$='.pdf']").attr("target", "_blank");

    // debug
    console.log(Foundation.MediaQuery.current);

});
